export const themeMui = {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#95C11F'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#95C11F',
      dark: 'rgba(4, 13, 23, 0.05)'
    },
    secondary: {
      main: '#040D17',
      dark: '#040D17',
      lowTariff: '#1BBC9B',
      highTariff: '#F53920',
      stepColor: '#040D17',
      stepSubtitleColor: '#040D17',
      blockBackgroundLight: '#fff'
    },
    text: {
      primary: '#040d17',
      secondary: '#000'
    }
  },
  typography: {
    h2: {
      fontSize: 20,
      fontWeight: 600
    },
    h3: {
      fontSize: 18,
      fontWeight: 600
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['Roboto, sans-serif'].join(',')
  }
};
